<template>
  <div class="home">
    <Header></Header>
    <div v-loading="loading">
      <!-- backtop -->
      <el-backtop :visibility-height="60">
        <i class="el-icon-caret-top" style="margin: auto"></i>
      </el-backtop>
      <div class="oo">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item v-for="(item, index) in banners" :key="index">
            <el-image class="banner" :src="item" fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="mt30 flex_items flex_center pb10">
        <div class="flex_column sub">
          <p class="bold f22 f-left">10秒获取房屋维修改造方案造价</p>
          <div class="flex_center">
            <div class="flex_start flex_items mt20">
              <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
              >
                <el-form-item>
                  <el-input
                    v-model="formInline.user"
                    placeholder="姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="formInline.phone"
                    placeholder="联系方式"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="formInline.region"
                    placeholder="房屋类目"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in wens"
                      :key="index"
                      :label="item.name"
                      :value="item.category_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    style="background: #007B46; border: none"
                    >立即申请</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="bgwhite">
        <div class="flex_column flex_items">
          <img
            :src="imgUrl + 'home/biaotisanjiao.png'"
            alt=""
            class="biaotisanjiao mt30"
          />
          <p class="f-center f12">
            Y I N I A O W E I X I U S E R V I C E P R O J E C T
          </p>
          <div class="flex_items flex_center fuwuxiang" style="width: 100%">
            <div class="flex_start flex_items" style="width: 100%">
              <div class="line mr15"></div>
              <div class="bold f28 mr15" style="width: 240px">服务项目</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_center flex_items bgwhite pt30">
        <div class="flex_start flex_items mt30" style="width: 1200px">
          <div
            v-for="(item, index) in fuwus"
            :key="index"
            :class="[
              'plr20',
              'pos',
              fuwu == item.t1 ? 'fuwuitem1' : 'fuwuitem',
            ]"
            @mouseenter="changefuwu(item)"
          >
            <div class="flex_center flex_items" style="margin-top: 40px">
              <div class="flex_column flex_items">
                <img
                  :src="item.t1 == fuwu ? item.src : item.src1"
                  alt=""
                  class="qiang mt15"
                />
                <p class="bold f19 mr10">{{ item.t1 }}</p>
                <p class="mt20 f14" v-html="item.t2"></p>
                <el-button
                  :style="{
                    color: fuwu == item.t1 ? 'white' : 'black',
                    background: 'none',
                  }"
                  class="cxq abs"
                  @click="cha(item.t1)"
                  >查看详情</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 核心优势 -->
      <div class="bgwhite pt20">
        <div class="flex_column flex_items pb20">
          <img
            :src="imgUrl + 'home/biaotisanjiao.png'"
            alt=""
            class="biaotisanjiao mt30"
          />
          <p class="f-center f12">
            Y I N I A O W E I X I U C O R E S T R E N G T H S
          </p>
          <div class="flex_items flex_center fuwuxiang" style="width: 100%">
            <div class="flex_start flex_items" style="width: 100%">
              <div class="line mr15"></div>
              <div class="bold f28 mr15" style="width: 240px">
                益鸟美居 核心优势
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_center flex_items bgwhite">
        <div style="width: 1200px">
          <img :src="imgUrl + 'home/3.png'" alt="" class="hexin mt20" />
          <img :src="imgUrl + 'home/4.png'" alt="" class="hexin" />
          <img :src="imgUrl + 'home/two.png'" alt="" class="hexin" />
          <!-- <img :src="imgUrl + 'home/one.png'" alt="" class="hexin" /> -->
        </div>
      </div>
      <div class="flex_items flex_center bgwhite pt30 pb20">
        <div class="flex_column sub">
          <p class="bold f22 f-left mt30">10秒获取房屋维修改造方案造价</p>
          <div class="flex_center">
            <div class="flex_start flex_items mt20">
              <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
              >
                <el-form-item>
                  <el-input
                    v-model="formInline.user"
                    placeholder="姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="formInline.phone"
                    placeholder="联系方式"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="formInline.region"
                    placeholder="房屋类目"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in wens"
                      :key="index"
                      :label="item.name"
                      :value="item.category_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    style="background: #007B46; border: none"
                    >立即申请</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="bgwhite zhuanli">
        <div class="" style="color: white">
          <div class="flex_column flex_items" style="padding-top: 100px">
            <img
              :src="imgUrl + 'home/baise.png'"
              alt=""
              class="biaotisanjiao"
              style="margin-top: 0"
            />
            <p class="f-center f12">
              Y I N I A O W E I X I U T H E P A T E N T A D V A N T A G E
            </p>
            <div class="flex_items flex_center fuwuxiang" style="width: 100%">
              <div class="flex_start flex_items" style="width: 100%">
                <div class="line mr15" style="background: white"></div>
                <div class="bold f28 mr15" style="width: 240px">
                  益鸟专利优势
                </div>
                <div class="line" style="background: white"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex_center flex_items" style="margin-top: 50px">
          <div class="shilun" style="width: 1200px">
            <el-carousel :interval="5000" arrow="always" @change="videochange">
              <el-carousel-item v-for="(item, index) in videos" :key="index">
                <div class="flex_start flex_items" style="width: 1200px">
                  <video-player
                    ref="videoPlayer"
                    class="video-player vjs-custom-skin shipintu mr15"
                    :playsinline="true"
                    :options="playerOptions"
                  />
                  <div class="flex_column">
                    <p class="f28 f-left" style="color: #f5ca91">
                      {{ item.title }}
                    </p>
                    <p class="f15 mt5 f-left" style="color: white">
                      {{ item.second_level }}
                    </p>
                    <div
                      class="p10 f14 mt15"
                      style="color: white; background: #f5ca91; width: 200px"
                    >
                      {{ item.three_levels }}
                    </div>
                    <p class="f13 mt20 f-left" style="color: white">
                      项目特点:
                    </p>
                    <p class="f13 mt15 f-left" style="color: #f5ca91">
                      {{ item.characteristic }}
                    </p>
                    <p class="f13 mt20 f-left" style="color: white">
                      明管工艺:
                    </p>
                    <p class="f13 mt15 f-left" style="color: #f5ca91">
                      {{ item.technology }}
                    </p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <!-- 益鸟服务流程 -->
      <div class="pb20">
        <div class="flex_column flex_items">
          <img
            :src="imgUrl + 'home/biaotisanjiao.png'"
            alt=""
            class="biaotisanjiao mt30"
          />
          <p class="f-center f12">Y I N I A O S E R V I C E P R O C E S S</p>
          <div class="flex_items flex_center fuwuxiang" style="width: 100%">
            <div class="flex_start flex_items" style="width: 100%">
              <div class="line mr15"></div>
              <div class="bold f28 mr15" style="width: 240px">益鸟服务流程</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_center flex_items">
        <div class="fufuk">
          <img :src="imgUrl + 'home/fuw.png'" alt="" class="fufu" />
        </div>
      </div>
      <!-- fuw2 -->
      <div class="fuw2k mt20 pt30 pos" style="color: white">
        <p class="bold f32 f-center mt20">业主口碑看得见</p>
        <p class="mt10 f15 f-center">100000+ 房屋诊断分析数据，97.6%好评率</p>
        <div
          class="flex_column sub abs"
          style="left: 0; right: 0; bottom: 20px; margin: auto"
        >
          <p class="bold f22 f-center mt30">10秒获取房屋维修改造方案造价</p>
          <div class="flex_center">
            <div class="flex_start flex_items mt20">
              <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
              >
                <el-form-item>
                  <el-input
                    v-model="formInline.user"
                    placeholder="姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="formInline.phone"
                    placeholder="联系方式"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="formInline.region"
                    placeholder="房屋类目"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in wens"
                      :key="index"
                      :label="item.name"
                      :value="item.category_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    style="background: #007B46; border: none"
                    >立即申请</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <!-- 经典案例 -->
      <div>
        <div class="bgwhite">
          <div class="flex_column flex_items">
            <img
              :src="imgUrl + 'home/biaotisanjiao.png'"
              alt=""
              class="biaotisanjiao mt30"
            />
            <p class="f-center f12">
              Y I N I A O W E I X I U T H E C A S E S H O W S
            </p>
            <div class="flex_items flex_center fuwuxiang" style="width: 100%">
              <div class="flex_start flex_items pb30" style="width: 100%">
                <div class="line mr15"></div>
                <div class="bold f28 mr15" style="width: 240px">
                  益鸟美居经典案例
                </div>
                <div class="line"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 经典案例 -->
        <div class="pt20 flex_center flex_items bgwhite">
          <div class="flex_start flex_items anlititle">
            <div
              v-for="(item, index) in anlits"
              :key="index"
              @click="anlichange(index, item.name)"
              :class="['anliitem', anliindex == index ? 'selecrtanli' : '']"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="pt20 bgwhite" v-loading="loading2">
          <div v-if="anlis.length != 0" class="flex_start flex_items">
            <div
              class="juju pos"
              v-for="(item, index2) in anlis"
              :key="index2"
              @mouseenter="yijin(index2)"
              @mouseleave="yichu(index2)"
              @click="xiangq(item.id)"
            >
              <el-image
                :src="tuhose + item.url"
                alt=""
                class="anan abs"
                fit="cover"
              ></el-image>
              <div
                class="abs anlixia flex_center flex_items flex_column"
                style="color: white"
                v-if="item.zt == 0"
              >
                <div class="flex_start flex_items">
                  <div class="li mr10"></div>
                  <div class="gaiTT f17 mr10">{{ item.title }}</div>
                  <div class="li"></div>
                </div>
                <p class="mt20 f14">{{ item.name }}</p>
              </div>
              <div
                v-else
                class="abs anlixia2 flex_center flex_items flex_column"
                style="color: white"
              >
                <div style="width: 150px">
                  <p class="f17 f-left">{{ item.xiaoqu }}</p>
                  <p class="mt10 f14 f-left">房屋面积:{{ item.area }}m²</p>
                  <p class="mt10 f14 f-left">房屋户型:{{ item.house_type }}</p>
                  <p class="mt10 f14 f-left">
                    装修风格:{{ item.decoration_style }}
                  </p>
                  <p class="mt10 f14 f-left">施工方式:{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex_center flex_items">
            <span class="f18 ptb20">--暂无数据信息--</span>
          </div>
        </div>
        <!-- 施工工地 -->
        <div class="cizhuan">
          <div class="flex_column flex_items">
            <img
              :src="imgUrl + 'home/biaotisanjiao.png'"
              alt=""
              class="biaotisanjiao mt30"
            />
            <p class="f-center f12">
              C O N S T R U C T I O N S I T E I N P R O G R E S S
            </p>
            <div class="flex_items flex_center fuwuxiang" style="width: 100%">
              <div class="flex_start flex_items" style="width: 100%">
                <div class="line mr15"></div>
                <div class="bold f28 mr15" style="width: 240px">
                  正在施工工地
                </div>
                <div class="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="gongdi plr20 flex_start pt30 mt20">
          <div
            class="flex_column mr30 ml30 mhg"
            style="
              color: white;
              margin-left: 210px;
              width: 332px;
              margin-top: 130px;
            "
          >
            <p class="f16 f-left mt30">工地参观在线预约</p>
            <el-input
              placeholder="您的姓名"
              prefix-icon="el-icon-user-solid"
              v-model="formInline.user"
              class="mt30 inin"
            >
            </el-input>
            <el-input
              placeholder="手机号码"
              prefix-icon="el-icon-phone"
              v-model="formInline.phone"
              class="mt30 inin"
            >
            </el-input>
            <el-input
              placeholder="小区名称"
              prefix-icon="el-icon-office-building"
              v-model="formInline.xiaoqu"
              class="mt30 inin"
            >
            </el-input>
            <el-button @click="onSubmit" type="warning" style="margin-top: 30px"
              >立即预约</el-button
            >
          </div>
          <div class="pos huang">
            <p
              class="f22 bold f-center mt15"
              style="color: white; padding-left: 40px"
            >
              施工工地一览表
            </p>
            <div class="flex_start flex_items" style="margin-top: 65px">
              <div class="bold f18 gunt f-center ptb5">小区名称</div>
              <div class="bold f18 gunt f-center ptb5">项目类型</div>
              <div class="bold f18 gunt f-center ptb5">项目名称</div>
              <div class="bold f18 gunt f-center ptb5">施工面积</div>
              <div class="bold f18 gunt f-center ptb5">状态</div>
            </div>
            <div class="marquee">
              <div class="marquee_box" ref="marquee_box" style="height: 400px">
                <ul
                  class="marquee_list"
                  :class="{ marquee_top: animate }"
                  style="width: 100%"
                >
                  <li v-for="(item, index) in list" :key="index">
                    <div class="flex_start flex_items mt20">
                      <div class="f14 gunt f-center ptb5">
                        {{ item.residential_quarters }}
                      </div>
                      <div class="f14 gunt f-center ptb5">
                        {{ item.pro_id_title }}
                      </div>
                      <div class="f14 gunt f-center ptb5">
                        {{ item.pro_id1_title }}
                      </div>
                      <div class="f14 gunt f-center ptb5">
                        {{ item.area }}m²
                      </div>
                      <div class="f14 gunt f-center ptb5">
                        {{ item.state }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="bgwhite pb20" style="padding-bottom: 60px">
          <div class="flex_column flex_items">
            <img
              :src="imgUrl + 'home/biaotisanjiao.png'"
              alt=""
              class="biaotisanjiao mt30"
            />
            <p class="f-center f12">Y I N I A O W E I X I U N E W S</p>
            <div class="flex_items flex_center fuwuxiang" style="width: 100%">
              <div class="flex_start flex_items" style="width: 100%">
                <div class="line mr15"></div>
                <div class="bold f28 mr15" style="width: 240px">新闻动态</div>
                <div class="line"></div>
              </div>
            </div>
            <div
              class="news flex_between flex_items mt40 pt20"
              style="width: 100%; cursor: pointer"
              v-if="xinwens.length != 0"
              @click="xiwnenxiang(xinwens[0].id)"
            >
              <div
                class="newstu flex_column"
                style="color: white; background: #007B46"
              >
                <p class="f24 mt30 f-left plr20">
                  {{ xinwens[0].title }}
                </p>
                <p class="f16 mt20 f-left plr20">
                  {{ xinwens[0].subtitle }}
                </p>
              </div>
              <img :src="tuhose + xinwens[0].url" alt="" class="newstu" />
            </div>
          </div>
          <div class="flex_center flex_items">
            <div
              class="news flex_start flex_items flex_start mt40"
              style="width: 100%"
            >
              <div
                v-for="(item, index) in xinwens.slice(1)"
                :key="index"
                class="flex_column mr15"
                style="cursor: pointer; width: 32%"
              >
                <div class="flex_column" @click="xiwnenxiang(item.id)">
                  <p class="f18 f-left">{{ item.nian }}</p>
                  <div
                    class="mt5"
                    style="width: 40px; height: 1px; background: black"
                  ></div>
                  <p class="mt5 f15 f-left">{{ item.yue }}</p>
                </div>
                <p
                  class="f18 bold mt10 f-left buxiang"
                  @click="xiwnenxiang(item.id)"
                  style="height: 55px"
                >
                  {{ item.title }}
                </p>
                <img
                  :src="tuhose + item.url"
                  alt=""
                  mode="aspectFill"
                  class="mt10"
                  style="height: 200px;"
                  @click="xiwnenxiang(item.id)"
                />
                <div class="f14 f-left mt5 xiazi" @click="xiwnenxiang(item.id)">
                  {{ item.subtitle }}
                </div>
                <el-button
                  @click="chanews()"
                  type="success"
                  style="
                    margin-top: 15px;
                    background: #007B46;
                    border: none;
                    width: 100px;
                  "
                  >查看更多</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 地图 -->
        <div class="ditu flex_items" style="color: white">
          <p class="f32 bold">益鸟愿景— —成为亿万用户信赖的综合家居服务品牌</p>
          <p class="f28 mt20">客户第一，说到做到</p>
          <div class="flex_items flex_center mt60">
            <div class="flex_around flex_items" style="width: 1200px">
              <div class="flex_items flex_center flex_column">
                <p class="bold f28">100000+</p>
                <p class="f22 mt10">房屋诊断分析数据</p>
              </div>
              <div class="flex_items flex_center flex_column">
                <p class="bold f28">35000+</p>
                <p class="f22 mt10">客户服务案例</p>
              </div>
              <div class="flex_items flex_center flex_column">
                <p class="bold f28">97.6%</p>
                <p class="f22 mt10">非常满意客户</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="bgwhite">
          <div class="flex_column flex_items">
            <img
              :src="imgUrl + 'home/biaotisanjiao.png'"
              alt=""
              class="biaotisanjiao mt30"
            />
            <p class="f-center f12">Y I N I A O W E I X I U P A R T N E R S</p>
            <div class="flex_items flex_center fuwuxiang" style="width: 100%">
              <div class="flex_start flex_items pb30" style="width: 100%">
                <div class="line mr15"></div>
                <div class="bold f28 mr15" style="width: 240px">合作伙伴</div>
                <div class="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex_center flex_items bgwhite pt30 pb30">
          <div class="news">
            <img :src="imgUrl + 'home/hezuohuoban.png'" alt="" class="huoban" />
          </div>
        </div>
      </div>
    </div>
    <Footer class=""></Footer>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      loading: true,
      loading2: false,
      wens: [],
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
      fuwus: [],
      fuwu: "",
      anlits: [],
      anlis: [],
      anli: "",
      anliindex: 0,
      // 滚动
      animate: false,
      showNum: 4, // 可见列表条数
      list: [],
      // 新闻咨询
      xinwens: [],
      // banner
      banners: [],
      // 视频
      videos: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "5:3", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", // url地址
          },
        ],
        hls: true,
        poster: this.util.imgUrl + "home/shipintu.png", // 你的封面地址
        width: "100%", // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //时间分割线
          durationDisplay: true, //总时间
          remainingTimeDisplay: false, //剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  created() {
    this.$store.commit("setpage", "首页");
    this.getData();
  },
  methods: {
    getData() {
      this.util.get(this.HOST + "/api/index", {}).then((res) => {
        if (res.errcode == 200) {
          this.xinwens = res.data.articles.data;
          for (let i = 0; i < res.data.Banner.length; i++) {
            this.banners.push(this.tuhose + res.data.Banner[i].image_lib.url);
          }
          this.fuwu = res.data.category_arr[0].name;
          for (let i = 0; i < res.data.category_arr.length; i++) {
            this.fuwus.push({
              src: this.util.tuhose + res.data.category_arr[i].images1[0].url,
              src1: this.util.tuhose + res.data.category_arr[i].images[0].url,
              t1: res.data.category_arr[i].name,
              t2: res.data.category_arr[i].describe,
            });
          }
          for (let i = 0; i < res.data.examples.length; i++) {
            res.data.examples[i].zt = 0;
          }
          this.anlits = res.data.category_arr;
          this.anlits.unshift({
            name: "全部",
          });
          this.videos = res.data.video;
          this.anlis = res.data.examples;
          this.list = res.data.stores;
          this.playerOptions.sources[0].src = res.data.video[0].url_path;
          setInterval(this.showMarquee, 2000);
          this.getlei();
        }
      });
    },
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then((res) => {
        if (res.errcode == 200) {
          this.wens = res.data;
          // localStorage.setItem("wens", JSON.stringify(res.data));
          this.loading = false;
        }
      });
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      let tt = 1;
      // if (this.formInline.xiaoqu != "") {
      //   tt = 20;
      // }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: tt,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
    changefuwu(item) {
      this.fuwu = item.t1;
    },
    xiangq(idx) {
      this.$router.push({
        path: "/casedetail",
        query: {
          id: idx,
        },
      });
    },
    anlichange(idx, name) {
      this.loading2 = true;
      this.anliindex = idx;
      this.util
        .get(this.HOST + "/api/makeAnAppointment", {
          service_category_id: name,
        })
        .then((res) => {
          if (res.errcode == 200) {
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].zt = 0;
            }
            this.anlis = res.data;
            this.loading2 = false;
          }
        });
    },
    yijin(idx) {
      this.anlis[idx].zt = 1;
    },
    yichu(idx) {
      this.anlis[idx].zt = 0;
    },
    cha(ming) {
      console.log(ming);
      let k = 0;
      if (ming == "墙面翻新") {
        k = 1;
      } else if (ming == "防水补漏") {
        k = 2;
      } else if (ming == "维修安装") {
        k = 3;
      }
      this.$router.push({
        path: "/product",
        query: { kind: k },
      });
    },
    chanews() {
      this.$router.push({
        path: "/news",
        query: {},
      });
    },
    xiwnenxiang(id) {
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: id,
        },
      });
    },
    // 处理数组方法
    showMarquee() {
      this.animate = true;
      this.list.push(this.list[0]);
      setTimeout(() => {
        this.list.shift();
        this.animate = false;
      }, 1000);
    },
    videochange(val) {
      this.playerOptions.sources[0].src = this.videos[val].url_path;
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
body {
  background: #f5f5f5;
}
.banner {
  width: 100%;
  height: 600px;
}
.oo .el-carousel__container {
  height: 600px !important;
}
.sub,
.shilun,
.fufuk,
.news {
  max-width: 1200px;
}
.fuwuxiang {
  max-width: 1200px;
}
.biaotisanjiao {
  width: 83px;
  height: 53px;
  margin-top: 110px;
}
.line {
  height: 1px;
  width: 465px;
  background: black;
}
.fuwuitem {
  width: 300px;
  height: 370px;
  background: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  /* transition: 0.3s ease-out; */
  /* box-shadow: 0px 1px 5px #4a4a4a; */
  box-shadow: 0px 10px 10px #efefef, 0px -2px 4px #efefef;
  /* box-shadow: 0px -10px 0px 0px #efefef,-2px 0px 0px 0px #efefef, 5px 0px 0px 0px #efefef, 5px 10px 0px 0px #efefef; */
  margin-right: 40px;
}
.fuwuitem1 {
  background: #007b46;
  color: white;
  width: 310px;
  height: 400px;
  margin-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 40px;
}
.fuwuitem:last-child,
.fuwuitem1:last-child {
  margin-right: 0 !important;
}
.qiang {
  width: 65px;
  height: 65px;
}
.hexin {
  width: 100%;
  /* height: 600px; */
}
.zhuanli {
  width: 100%;
  height: 880px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/home/beijing.png");
}
.shipintu {
  width: 100%;
  /* height: 320px; */
  margin-right: 40px;
}
.shilun .el-carousel__container {
  /* width: 900px; */
  height: 530px;
}
.fufu {
  width: 100%;
  height: 550px;
}
.fuw2k {
  width: 100%;
  height: 900px;

  background: url("https://imgaes.yiniaoweb.com/gw/home/fuw2.png") no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
}
.anlititle {
  width: 800px;
}
.anliitem {
  width: 120px;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  text-align: center;
  border-radius: 20px;
  margin-right: 85px;
  cursor: pointer;
}
.selecrtanli {
  background: #007b46;
  color: white;
}
.anliitem:last-child {
  margin-right: 0;
}
.juju {
  width: 25%;
  height: 550px;
  cursor: pointer;
}
.anan {
  width: 100%;
  height: 550px;
  left: 0;
  top: 0;
}
.anlixia {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background: rgba(10, 9, 8, 0.4);
}
.anlixia2 {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 220px;
  background: #007b46;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.li {
  height: 1px;
  width: 80px;
  background: white;
}
.cizhuan {
  width: 100%;
  height: 350px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/home/cizhuanbeijing.png");
}
.gongdi {
  background: #06332e;
}
.gongdi .el-input__inner {
  background: none !important;
}
.huang {
  width: 1017px;
  height: 634px;
  top: -135px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url(https://imgaes.yiniaoweb.com/gw/home/hh.png) no-repeat;
}
/* 滚动 */

.marquee {
  width: 100%;
  margin: 10px auto;
  /* height: 200px; */
  align-items: center;
  color: #3a3a3a;
  background-color: none;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
.marquee_title {
  padding: 0 20px;
  height: 21px;
  font-size: 14px;
  border-right: 1px solid #d8d8d8;
  align-items: center;
}
.marquee_box {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
.marquee_list {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
}
.marquee_top {
  transition: all 1s;
  margin-top: -30px;
}
.marquee_list li {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 20px;
}
.marquee_list li span {
  padding: 0 2px;
}
.gunt {
  width: 20%;
}
/* end */
.newstu {
  width: 50%;
  height: 300px;
}
.xinwen {
  /* width: 416px; */
  height: 130px;
}
.ditu {
  width: 100%;
  height: 340px;
  background: url(https://imgaes.yiniaoweb.com/gw/home/ditubeijing.png)
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  /* margin-top: 60px; */
  padding-top: 50px;
}
.huoban {
  width: 100%;
  height: 475px;
}
.home .el-loading-spinner {
  top: 4% !important;
}
.home .cxq {
  bottom: 58px;
}
.home .el-carousel__arrow {
  font-size: 48px !important;
  width: auto;
  height: auto;
}
.home .el-carousel--horizontal {
  overflow-x: visible !important;
}
/* .home .el-carousel__arrow--right {
  right: -80px !important;
}
.home .el-carousel__arrow--left {
  left: -80px !important;
} */
.home i {
  margin: 5px !important;
}
.mhg i {
  margin: 0 !important;
}
.home .buxiang {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  width: 100%;
  -webkit-box-orient: vertical;
}
.home .xiazi {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  width: 100%;
  -webkit-box-orient: vertical;
}
</style>
